<template>
  <div class="similar-company-name">
    <div v-if="creatingCompany" class="center-spinner">
      <ct-centered-spinner />
    </div>
    <div v-else class="w-100 text-center p-3">
      <h2 class="header">
        {{ slideTitle }}
      </h2>
      <div class="company-containers">
        <div class="existing-company-container">
          <div class="company-container">
            <div class="title-container">
              <span>Existing Company</span>
            </div>
            <div class="main-container">
              <div class="name-container">
                <p>{{ similarCompany?.name }}</p>
              </div>
              <button
                class="continue-btn btn btn-primary"
                aria-label="continue button"
                @click="goToSimilarCompany"
              >
                Go to company
              </button>
            </div>
          </div>
          <p class="change-name-email">
            Want to change this company name?<br>
            Email us at <a :href="`mailto:${supportEmail}`">{{ supportEmail }}</a>
          </p>
        </div>

        <div class="new-company-container">
          <div class="company-container">
            <div class="title-container">
              <span>New Company</span>
            </div>
            <div class="main-container">
              <div class="name-container">
                <p>{{ fullCompanyName }}</p>
              </div>
              <button
                class="continue-btn btn btn-outline-primary"
                aria-label="continue button"
                @click="goToConfirmCompany"
              >
                Continue with this name
              </button>
            </div>
          </div>
          <div class="button-nav-container">
            <previous-button-component button-text="Start over with a new name" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  export default {
    name: 'SimilarCompanyName',
    components : {
      CtCenteredSpinner:        () => import('@/components/shared/CtCenteredSpinner'),
      PreviousButtonComponent:  () => import('./PreviousButtonComponent'),
    },
    data() {
      return {
        creatingCompany: false,
      }
    },
    computed: {
      ...mapGetters('stagelineCreateCompany', [
        'similarCompany',
        'fullCompanyName',
      ]),
      ...mapGetters('stageline', [
        'accountCompanies',
        'companyStagelineRoute',
      ]),
      ...mapGetters('website', [
        'website',
      ]),
      slideTitle() {
        return 'There is already a company on your account by that name.'
      },
      supportEmail() {
        return this.website && this.website.emails ? this.website.emails[0] : null
      },
    },
    methods: {
      ...mapActions('stagelineCreateCompany', [
        'createCompany',
        'setCreatingCompany',
        'setCurrentSlide',
        'setLoaded',
      ]),
      ...mapActions('companies', [
        'setCurrentCompany',
      ]),
      async goToSimilarCompany() {
        await this.setCurrentCompany({ id: this.similarCompany.id, force: true })

        const stagelineRoute      = this.companyStagelineRoute
        const lastVisitedSlide    = this.similarCompany?.stageline?.last_visited_slide

        if (!stagelineRoute && !lastVisitedSlide) {
          await this.$router.push({ name: 'stageline-v2-decision-tree', params: { companyId: this.similarCompany.id } })
          this.loaded = true
        } else {
          await this.$router.push({
            name: 'stageline-v2-start',
            params: { companyId: this.similarCompany.id },
          })
        }
      },
      goToConfirmCompany() {
        this.setCurrentSlide('ConfirmCompany')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .similar-company-name {
    margin-left: 1.75rem;

    .header {
      max-width: 41.25em;
      margin-bottom: 4rem;
    }
    .company-containers {
      display: flex;
      flex-direction: row;
      gap: 2rem;

      .company-container {
        border-width: 1px;
        border-style: solid;
        border-radius: $stageline-primary-border-radius;
        width: 20rem;

        .title-container {
          width: 100%;
          border-radius: 0.1875rem 0.1875rem 0 0;
          height: 2.75rem;
          padding: 0.6rem;
        }
        .main-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 1rem 2.5rem 1.5rem 2.5rem;
          min-height: 10.5rem;

          .name-container {
            display: flex;
            align-items: center;
            align-self: center;
            min-height: 5rem;
          }
          button {
            width: 100%;
            padding: 0.5rem 1rem;
          }
        }
      }
      .existing-company-container {
        width: min-content;

        .company-container {
          border-color: $ct-ui-primary;

          .title-container {
            background-color: $ct-ui-primary;
            color: white;
          }
        }
        .change-name-email {
          margin-top: 1rem;
          font-size: small;
          color: rgba(0, 0, 0, 0.6);
        }
      }
      .new-company-container {
        .company-container {
          border-color: rgba(0, 0, 0, 0.15);

          .title-container {
            background-color: rgba(0, 0, 0, 0.075);
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
          }
          button {
            color: $ct-ui-primary;
            font-weight: 700;
            border-color: $ct-ui-primary;
            border-width: 0.125em;
          }
          button:hover {
            background-color: $ct-ui-primary-light !important;
          }
        }
      }
    }
    .button-nav-container {
      text-align-last: right;
      margin-top: 8rem;

      ::v-deep button.btn {
        color: $ct-ui-primary !important;
        padding-right: 0;
      }
    }
  }

  @media only screen and (max-width: 700px) {
    .company-containers {
      flex-direction: column !important;
      align-items: center;
    }
    .button-nav-container {
      margin-top: 3rem !important;
    }
  }
</style>
