var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "similar-company-name" }, [
    _vm.creatingCompany
      ? _c(
          "div",
          { staticClass: "center-spinner" },
          [_c("ct-centered-spinner")],
          1
        )
      : _c("div", { staticClass: "w-100 text-center p-3" }, [
          _c("h2", { staticClass: "header" }, [
            _vm._v("\n      " + _vm._s(_vm.slideTitle) + "\n    "),
          ]),
          _c("div", { staticClass: "company-containers" }, [
            _c("div", { staticClass: "existing-company-container" }, [
              _c("div", { staticClass: "company-container" }, [
                _vm._m(0),
                _c("div", { staticClass: "main-container" }, [
                  _c("div", { staticClass: "name-container" }, [
                    _c("p", [_vm._v(_vm._s(_vm.similarCompany?.name))]),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "continue-btn btn btn-primary",
                      attrs: { "aria-label": "continue button" },
                      on: { click: _vm.goToSimilarCompany },
                    },
                    [_vm._v("\n              Go to company\n            ")]
                  ),
                ]),
              ]),
              _c("p", { staticClass: "change-name-email" }, [
                _vm._v("\n          Want to change this company name?"),
                _c("br"),
                _vm._v("\n          Email us at "),
                _c("a", { attrs: { href: `mailto:${_vm.supportEmail}` } }, [
                  _vm._v(_vm._s(_vm.supportEmail)),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "new-company-container" }, [
              _c("div", { staticClass: "company-container" }, [
                _vm._m(1),
                _c("div", { staticClass: "main-container" }, [
                  _c("div", { staticClass: "name-container" }, [
                    _c("p", [_vm._v(_vm._s(_vm.fullCompanyName))]),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "continue-btn btn btn-outline-primary",
                      attrs: { "aria-label": "continue button" },
                      on: { click: _vm.goToConfirmCompany },
                    },
                    [
                      _vm._v(
                        "\n              Continue with this name\n            "
                      ),
                    ]
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "button-nav-container" },
                [
                  _c("previous-button-component", {
                    attrs: { "button-text": "Start over with a new name" },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-container" }, [
      _c("span", [_vm._v("Existing Company")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-container" }, [
      _c("span", [_vm._v("New Company")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }